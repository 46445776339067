.ConversationsPreloader {
    padding-left: 1rem; // why it's here
    // padding-top: 1rem;

    .NodeMainTitle {
        margin-bottom: 1rem;
    }

    .MuiGrid-item {
        margin-bottom: .5rem;
        padding: .5rem!important;
        border-radius: 15px;
        background: #f6f6f6;
    }

    .PreloaderMessage {
        display: flex;

        .Text {
            width: calc(100% - 100px);
            margin-left: 1rem;
            margin-top: .5rem;
        }

        &.HideAvatar {
            display: block;

            .Text {
                margin: 0;
                width: auto;
            }
        }
    }
}
