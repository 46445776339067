.shoppingCartIconBadge {
    margin-left: 1rem;

    .MuiBadge-badge {
        margin-right: 10px;
        z-index: 1050;
    }
}

.Cart {
    @media (min-width: 600px) {
        width: 540px;
    }

    // TODO: fix for telegram
    .CartPreloaderWrap {
        position: relative;
        min-height: 200px;
    }

    .MuiDialogContent-root {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .ShortOrderDescription {
        margin-bottom: 1rem;

        .ShortOrderDescriptionItem {
            display: flex;
            align-items: center;
        }

        .MuiSvgIcon-root {
            width: 18px;
            height: 18px;
        }

        .editProductOptions {
            margin-left: .5rem;
            align-items: center;
            display: flex;
        }
    }

    .ShortOrderDescriptionPrice {
        // margin-left: 1rem;
        display: block;
        white-space: nowrap;
        font-weight: 400;
        font-size: .8rem;
        // margin-bottom: 2rem;
        // padding-left: 2.8rem;

        // .price {
        //     font-size: 1.2rem;
        // }
    }

    .shopTitle {
        // margin-bottom: 1rem;
        padding: 1rem 0;
    }

    .startOrder {
        margin-bottom: 3rem;
    }

    .shopItem {
        position: relative;

        // padding: 1rem 0;

        // @media (min-width: 600px) {
        //     &:last-child {
        //         padding-bottom: 0;
        //     }
        // }

        &:first-child:after {
            display: none;
        }

        &:after {
            content: "";
            display: block;
            height: 1px;
            width: 100%;
            background: #ddd;
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    .shopTitle {
        color: #333;

        .titleWrap {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title {
                display: flex;
                align-items: center;
                font-weight: 500;
                width: 100%;

                .Logo {
                    margin-right: 1rem;
                }

                .Clickable {
                    cursor: pointer;
                }

                .name {
                    cursor: pointer;
                    // width: 100%;
                }

                .q {
                    white-space: nowrap;
                    opacity: .7;
                    font-weight: normal;
                    margin-left: .5rem;

                    .MuiChip-root {
                        height: auto;
                        top: -1px;
                        position: relative;

                        .MuiChip-label {
                            padding: 0 8px;
                            font-size: 10px;
                        }
                    }
                }
            }
        }

        .icon {
            position: relative;
            top: 3px;
            right: .5rem;
            cursor: pointer;
        }

        .icon.up {
            display: none;
        }

        .icon.down {
            display: inline-block;
        }

        &.active {
            .icon.up {
                display: inline-block;
            }

            .icon.down {
                display: none;
            }
        }
    }

    .FormButtons {
        margin-bottom: 3rem;
    }

    .productsList {
        // border-bottom: 1px solid #ccc;
        margin-bottom: 1rem;
        padding-left: 1rem;
        // padding-top: 2rem;

        &:last-child {
            border-color: #fff;
        }

        @media (min-width: 600px) {
            padding-left: 2rem;
        }

        .editProductOptions {
            cursor: pointer;
            position: absolute;
            right: .5rem;
            top: 0;
            opacity: .8;
        }
    }

    .finalPrice {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 599px) {
            padding-bottom: 2rem;
        }

        .MuiButtonBase-root {
            min-width: 140px;
        }

        .price {
            font-size: 0.8rem;
            padding-right: .5rem;

            .priceValue {
                display: block;
                font-size: 1.2rem;
            }

            @media (min-width: 600px) {
                // padding-left: 1rem;

                .priceValue {
                    font-size: 1.3rem;
                }
            }
        }

        .button {
            @media (min-width: 600px) {
                // margin-right: 1rem;
                margin-left: 2rem;
            }
        }
    }

    .product {
        position: relative;
        overflow: hidden;
        // margin-bottom: 2rem;
        border-top: 1px solid #e1e1e1;
        padding-top: 1rem;

        &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
        }

        .ShopProductPrice {
            .oldPrice {
                font-size: .8rem;
            }
        }

        .details {
            display: flex;
            margin-bottom: 1rem;

            .up {
                display: none;
            }

            &.opened {
                .up {
                    display: inline;
                }
                .down {
                    display: none;
                }
            }
        }

        .oldPrice {
            text-decoration: line-through;
            color: #888;
            font-size: 1rem;
            margin-bottom: -.2rem;
        }

        .actions {
            display: flex;
            align-items: center;
            margin-top: 1rem;

            .MuiSvgIcon-root {
                margin-right: .5rem;
            }
        }

        // .form {
        //     .labelWrap {
        //         display: inline-flex;
        //         margin-right: .5rem;
        //     }
        // }

        .quantity {
            margin-top: 1rem;
            margin-bottom: 1rem;

            .wrap {
                display: flex;
                align-items: center;
                margin-left: -1rem;

                width: 100%;
            }

            .btn {
                margin: 0 1rem;
                cursor: pointer;
            }
        }

        .details,
        .quantity,
        .form,
        .options,
        .addons,
        .selectedPrice {
            margin-left: 80px;
        }

        .addons,
        .options {
            .label {
                display: block;
                font-size: 12px;
            }

            .labelWrap,
            .labelValue {
                .MuiChip-root {
                    margin-top: 0;
                }
            }

            .labelValue {
                .label,
                .labelValue {
                    display: inline;
                    font-size: 1rem;
                }

                &.labelWrap {
                    margin-top: .5rem;
                    margin-bottom: .5rem;
                }
            }

            .labelValue {
                display: flex;
                align-items: center;
            }

        }

        .options + .addons {
            margin-top: 1.5rem;
        }

        .quantity {
            margin-top: 0;
        }
    }

    .FormContainerWrap {
        padding-bottom: 0;
    }
}

.SwipeableDrawerContentWrapper {
    .Cart {
        margin-top: -1rem;
    }
}

.productOptionAdditionalPrice {
    opacity: .6;
}

.AddProductOptions {
    margin-bottom: 1rem;
}

.AddProductOptions,
.EditProductOptions {
    .price {
        padding-left: 1rem;
    }    
}

.ProductDetails {
    display: flex;
    // margin-bottom: 1rem;

    &.NoPrice {
        align-items: center;
    }

    .product {
        position: relative;
    }

    .picture {
        // float: left;
        margin-right: 1rem;
        width: 64px;
        height: 64px;
        border-radius: 15px;
    }

    .subtitle {
        // padding-right: 3rem;
        margin-bottom: .7rem;
    }

    .title {
        font-weight: 500;
        // padding-right: 3rem;
        // margin-bottom: .5rem;

        .wrap:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .price {
        font-size: 1.2rem;
        margin-top: .5rem;
        margin-bottom: 1.2rem;
        padding-left: 0;

        .label {
            font-size: 1rem;
        }

        .pricePrefix {
            top: 4px;
            position: relative;
            margin-left: .5rem;
            font-size: 1rem;
        }
    }
}

.productOptionsSelectList {
    margin-bottom: 2rem;
}

.productAddonsSelectList {
    // padding-bottom: 2rem;

    .MuiGrid-container {
        margin-bottom: 1rem;
    }

    .title {
        cursor: pointer;
    }

    .MuiGrid-item {
        display: flex;
        align-items: center;
    }

    .currencyTitle {
        padding-left: 1rem;
    }

    .currencyPrice {
        @media (max-width: 599px) {
            justify-content: end;

            .price {
                margin-right: 30px;
            }

            // margin-bottom: 1.5rem;
            // padding-left: 3rem;
            font-size: 1.1rem;
        }
    }

    .quantityCell {
        justify-content: end;
    }

    .quantity {
        .label {display: none;}

        @media (max-width: 599px) {
            padding: 1rem 0 2rem;
            padding-left: 1rem;
            display: flex;
            align-items: center;

            .label {
                display: block;
                margin-bottom: 3px;
                margin-right: 5px;
            }

            .quantity .wrap .MuiSvgIcon-root {
                margin: 0 20px;
            }
        }

        .wrap {
            display: flex;
            align-items: center;

            .MuiSvgIcon-root {
                margin: 0 10px;
            }
        }
    }
}

// @media (max-width: 599px) {
//     .ProductDetailsWrap {
//         padding-bottom: 2rem;
//     }
// }
