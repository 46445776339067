.LinksLines {
    .LinksTileImageLink {
        .banner {
            min-height: 192px;

            @media (max-width: 599px) {
                min-height: 140px;
            }
        }
    }

    &.VirtualCatalogs {
        .mainSection {
            display: flex;
            align-items: center;
        }

        .MuiBadge-badge {
            margin-top: 10px;
            margin-right: 10px;
        }

        .LinksTileImageLink {
            .banner,
            .thumbnail {
                min-height: auto;
            }
        }

        .LinksTileItem {
            .title {
                .titleText {
                    font-size: 1rem;
                }
            }
        }
    }
}

.LinksLinesWrap {
    &.VirtualCatalogsWrap {
        position: relative;
        overflow: hidden;

        margin-bottom: 0rem!important;
        padding-left: .5rem!important;

        @media (min-width: 600px) {
            margin-bottom: 1.5rem!important;
        }

        &::before {
            content: '';
            display: block;
            width: 0;
            height: 100%;
            box-shadow: 0 0 8px 5px #fff;
            position: absolute;
            right: 0;
            z-index: 1;
        }
    
        .ActionButton {
            display: none;
        }
    
        .LinksTileItem {
            &.active {
                // .photoWrap {
                //     opacity: .3;
                // }
    
                .titleLink {
                    font-weight: bold;
                }

                .thumbnail {
                    border: 5px solid rgba(129, 195, 255, 0.3803921569);
                    // position: relative;
                    // z-index: 1;
                }
            }
        }

        // .LinksTileItem.active .photoWrap {
        //     opacity: 1;

            
        // }

        .LinksTileItem.no_badge {
            opacity: .5;

            .LinksTileImageLink {
                cursor: not-allowed;
            }
        }

        .MuiGrid-container {
            padding-bottom: 0;
            display: block;
            text-align: left;
            white-space: nowrap;
            margin-bottom: -1rem!important;
            overflow-x: auto;
            overflow-y: hidden;

            .MuiGrid-item {
                padding-bottom: 1rem;

                &:last-child {
                    margin-right: 3rem;
                }
            }

            img.thumbnail {
                border: 5px solid #fff;
                border-radius: 50%;
                width: 76px;
            }

            .title {
                display: block;
                text-align: center;

                .titleText {
                    font-size: 14px;
                    // position: relative;
                    // z-index: 1;
                }
            }

            @media (max-width: 599px) {
                
            }
        }
    
        .LinksTileImageWrap {
            margin-bottom: 0;
        }
    
        .MuiGrid-item {
            display: inline-block;
        }
    }    
}
