.actionsList {
    position: relative;
    height: 7rem;

    .actionsListButtons {
        position: fixed;
        z-index: 100;
        bottom: 72px;
        height: 0;

        width: calc(100% - 2rem);

        text-align: right;

        display: flex;
        justify-content: right;
    }

    @media (min-width: 600px) {
        height: 0;

        .actionsListButtons {
            width: calc(900px + 2rem);
        }
    }

    button {
        margin-left: .5rem;
        border-radius: 50%;
    }

    .NodeActionsButton {
        min-width: 56px;

        &.Close {
            background: #eee;
            color: #333;
        }
    }

    .MuiFab-contrast,
    .MuiFab-contrast:hover,
    .MuiFab-contrast:focus {
        background: #333;
        color: #fff;
        box-shadow: none;
    }

    .MuiFab-neutral,
    .MuiFab-neutral:hover,
    .MuiFab-neutral:focus {
        background: #fff;
        color: #333;
        border: 1px solid #ccc;
        box-shadow: none;
    }
    
    .validateContentIcon,
    .validateContentIcon:hover{
        color: #33a448;

        &.Unpublish {
            color: #df4242;
        }
    }

    .removeContentIcon,
    .removeContentIcon:hover {
        color: #df4242;
    }
}

.AddNodeMenu {
    .MuiPopover-paper {
        margin-top: -74px;
    }
}

.addContentIconMenu {
    overflow: hidden;
    padding: 0;
    margin: 0;
    margin-top: -2rem;
    min-width: 280px;

    &.columns {
        padding-top: .5rem;
    }

    .node {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 90%;
        overflow: hidden;
        border: 1px solid #e1e1e1;
        border-radius: 10px;
        padding: .7rem 0;
        font-size: .9rem;
        transition: background .5s ease-in-out;

        svg {
            margin-right: .4rem;
            width: 20px;
            height: 20px;
        }

        &:hover {
            background: rgba(0, 0, 0, .04);
        }
    }

    .menuItem {
        margin-top: .5rem;
        margin-bottom: .5rem;

        &.fullWidth {
            width: 100%!important;
        }

        &.first {
            clear: both;

            &::before {
                content: '';
                display: block;
                height: 1px;
                width: 200%;
                position: absolute;
                left: 0;
                top: -21px;
                opacity: 0.8;
                background: #eee;
            }
        }

        &.first,
        &.second {
            margin-top: 2rem;
        }
    }

    .MuiPaper-root {
        margin-top: -76px;
        padding: .5rem;
    }

    .MuiDivider-root:last-child {
        display: none;
    }

    .MuiList-root {
        width: 160px;
    }

    .MuiMenuItem-root {
        width: 100%;
        padding: 0;

        &:hover {
            background: none!important;
        }
    }

    &.columns {
        margin-left: -.5rem;
        margin-right: -.5rem;

        .MuiList-root {
            width: 320px;
        }

        .MuiMenuItem-root {
            width: 50%;
            float: left;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.DialogContentWrapper {
    .addContentIconMenu .menuItem.first,
    .addContentIconMenu .menuItem.second {
        padding-top: 0;
        padding-bottom: 0;
    }
}