.CellMessage,
.CellConversation {
    .NotificationActions {
        > .pseudoLink,
        .MuiButton-root {
            margin-bottom: .5rem;

            @media (min-width: 600px) {
                margin-bottom: 0;
            }
        }
    }

    .timestamp {
        opacity: .5;
        font-size: 12px;
        font-weight: normal;
        margin: 0;
        display: flex;
        align-items: center;

        .MuiSvgIcon-root,
        .MuiCircularProgress-root {
            margin-left: .5rem;
            width: 16px!important;
            height: 16px!important;
        }

        .MuiSvgIcon-root {
            opacity: .5;
        }

        .readed {
            color: #00911a;
            opacity: 1;
        }
    }

    .CellConversationTitle {
        font-weight: bold;
        margin-bottom: .2rem;
        font-size: 1.2rem;
        margin-top: -.3rem;
    }

    .text {
        position: relative;
    }
}

.CellMessage {
    .message {
        display: flex;
        align-items: start;

        @media (min-width: 600px) {
            align-items: center;
        }    
    }

    .avatar {
        margin-right: 1rem;

        .Avatar {
            width: 20px;
            height: 20px;
            min-width: 20px;
            min-height: 20px;
        }
    }

    .text {
        width: calc(100vw - 162px);

        @media (min-width: 600px) {
            width: 100%;
        }
    }

    pre {
        margin-bottom: 0;
    }

    .timestamp {
        font-size: 10px;
    }

    .name {
        font-size: 12px;
        margin-bottom: .5rem;
        display: flex;
        align-items: center;

        @media (min-width: 600px) {
            font-size: 1rem;
        }

        .avatar {
            font-size: 0;
            margin-right: .5rem;
        }

        .MuiChip-label {
            font-size: 10px;
        }

        .MuiChip-root {
            margin-left: .5rem;
            height: auto;
        }
    }

    &.sender,
    &.privateConversation {
        .name,
        .avatar {
            display: none;
        }

        .text {
            width: 100%;
        }

        .timestamp {
            justify-content: end;
        }
    }

    .NotificationTitle {
        margin-bottom: .5rem;
    }
}

.tr_unseen {
    font-weight: normal!important;
    font-weight: 500!important;
}

.td_conversation-logo {
    padding-right: .2rem;
}

.td_conversation-open .moreBtnLink {
    display: block;
}
