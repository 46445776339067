.ProductAddons {
    .MuiChip-root {
        margin-right: .5rem;
        margin-bottom: .5rem;
    }

    .label {
        font-size: 14px;
        margin-bottom: .5rem;
    }

    .labelWrap {
        .label {
            font-size: 1rem;
        }

        padding-left: 0 !important;
        margin-top: 0.5rem;
    }
}

.Content {
    .ProductAddons {
        padding-left: 1rem;
        padding-right: 1rem;

        margin-bottom: 2rem;

        @media (min-width: 600px) {
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }
}
