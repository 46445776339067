.NotificationBadge {
    .MuiBadge-badge {
        top: 1px;
    }
}

.ConversationBadge {
    .MuiBadge-badge {
        top: 6px;
        margin-right: 10px;
    }
}
