.CalendarWidget {
    position: absolute;
    top: -334px;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;

    width: 100%;
    height: 0;

    transition: height .1s ease-in;
    overflow: hidden;

    .MuiPickersSlideTransition-root {
        display: flex;
        align-items: center;
    }

    .MuiDayCalendar-monthContainer {
        
    }
 
    .MuiDayCalendar-loadingContainer {
        position: relative;
        display: flex;
        align-items: center;

        .MuiDayCalendarSkeleton-root {
            align-self: center;
        }
    }

    // TODO: return if animation will be enabled
    // .MuiDayCalendar-loadingContainer > div:first-child {
    //     position: absolute;
    // }

    // .MuiPickersSlideTransition-root > div:first-child,
    // .MuiDayCalendar-loadingContainer > div:first-child {
    //     // position: absolute;
    //     transform: translateY(-50%);
    //     top: 50%;
    //     // margin-top: 0;
    // }

    .MuiPickersSlideTransition-root > div:nth-child(2) {
        display: none;
    }

    .MuiDateRangeCalendar-root,
    .MuiDateRangeCalendar-monthContainer {
        width: 100%;
    }

    .MuiDayCalendarSkeleton-daySkeleton,
    .MuiButtonBase-root {
        width: 40px!important;
        height: 42px!important;
    }

    .MuiDayCalendarSkeleton-daySkeleton {
        margin: 2px 5px;
        width: 36px!important;
        height: 36px!important;
    }

    .MuiButtonBase-root {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .MuiDayCalendar-weekContainer {
        width: 310px;
    }

    .MuiDayCalendar-weekContainer {
        margin: 0;
        justify-content: left;

        &:first-child {
            justify-content: right;
        }
    }

    .MuiDayCalendar-weekDayLabel {
        width: 42px;
        height: 18px;
        justify-items: start;
        padding-top: .5rem;
    }

    // .MuiPickersSlideTransition-root {
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    // }
    
    // MuiDayCalendar-slideTransition 

    .MuiPickersCalendarHeader-root {
        padding-left: 1rem;
        padding-right: 0rem;
    }

    .MuiTypography-root,
    .MuiButtonBase-root {
        color: #fff;
        text-shadow: 0 0 10px #999;
    }

    .MuiTypography-root {
        font-weight: bold;
        text-shadow: none;
    }

    .MuiDayCalendar-header {
        justify-content: left;
    //     padding-left: 1rem;
    //     padding-right: 1rem;
    }

    .MuiButtonBase-root.Mui-selected,
    .MuiButtonBase-root.Mui-selected:focus,
    .MuiButtonBase-root.Mui-selected:hover {
        background-color: #fff;
        color: #333;
        text-shadow: none;
    }

    .MuiButtonBase-root:not(.Mui-selected) {
        border-color: #fff;
    }

    .MuiDateRangeCalendar-root > div:first-child {
        display: none;
    }
}

// .CalendarWidget {
//     .MuiPickersArrowSwitcher-root {
//         display: none;
//     }
// }
