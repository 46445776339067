.ActionText {
    // margin-bottom: 1rem!important;

    + .OrderActions {
        margin-top: 1rem;
    }
}

.OrderActions {
    // margin-bottom: .5rem;
    position: relative;

    &.OrderActionsMono {
        .MuiChip-root,
        .MuiButtonBase-root {
            width: 100%;
        }   
    }

    &.inProgress {
        .MuiButton-root {
            opacity: .5;
        }
    }

    .preloader {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .MuiButton-root {
        margin-bottom: .5rem;
    }

    @media (max-width: 599px) {
        .MuiChip-root,
        .MuiButtonBase-root {
            width: 100%;
        }

        .MuiChip-root {
            margin-bottom: .5rem;
        }

        .MuiButton-root {
            margin-bottom: .5rem;
        }
    }

    @media (min-width: 600px) {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: .5rem;
        margin-left: -.5rem;
        margin-right: -.5rem;

        .MuiButton-root,
        .pseudoLink {
            width: calc(50% - 1rem);
            margin-left: .5rem;
            margin-right: .5rem;
        }

        .pseudoLink .MuiButton-root {
            margin-left: 0;
            margin-right: 0;
            width: 100%;
        }
    }
}