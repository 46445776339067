.LinksTile {
    // text-decoration: none;
    text-align: center;
    position: relative;

    // .filterButton {
    //     position: absolute;
    //     right: 0;
    //     top: -22px;
    //     z-index: 1;
    // }

    .titleText {
        width: 100%;
        font-size: 1.2rem;
    }

    .filtersWrap .FiltersLine {
        width: 100%;
        z-index: 1;

        .FilterField {
            margin-left: 0;
            margin-right: 1rem;
            width: 50%;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    @media (min-width: 600px) {
        .filtersWrap .FiltersLine {
            position: absolute;
            right: 0;
            top: -22px;
            width: auto;

            .FilterField {
                width: 140px;
            }
        }
    }

    // .filtersWrap {
    //     display: flex;
    //     width: 100%;
    //     align-items: center;
    //     justify-content: end;
    //     padding: 0 0 0 2rem;
    //     margin-top: 1.5rem;
    //     margin-bottom: -1rem;

    //     .FilterField {
    //         width: 100px;

    //         margin-left: 1rem;

    //         @media (min-width: 600px) {
    //             width: 160px;
    //         }
    //     }
    // }

    .banner {
        // height: 100%;
        width: 100%;
        border-radius: 15px;
    }

    .InlineActionsWrap {
        position: relative;
    }

    .LinksTileItem {
        position: relative;
        transition: opacity .5s ease-in-out;
        margin-bottom: 1rem;

        @media (min-width: 600px) {
            margin-bottom: 0;
        }
    }

    a {
        text-decoration: none;
        color: #333;
        display: block;
        position: relative;
        font-size: 0;
    }

    .title {
        // color: #fff;
        // background: rgba(0,0,0,.8);
        // position: relative;
        // text-wrap: nowrap;
        // overflow: hidden;
        // padding: 0.5rem 0;
        // position: absolute;
        // left: 0;
        // bottom: 0;
        // width: 100%;
        // border-bottom-left-radius: 15px;
        // border-bottom-right-radius: 15px;
        // font-weight: bold;
        cursor: pointer;

        display: inline-flex;
        align-items: center;

        font-size: 1.2rem;
        line-height: 1.1;
        font-weight: 400;
        // padding-left: .5rem;
        // padding-right: .5rem;
        // line-height: 1.7rem;
        color: #333;
        text-align: left;
        // margin-bottom: .5rem;

        width: 100%;
        box-sizing: border-box;
    }

    .description {
        display: none;
    }
}

.LinksTileImageWrap {
    position: relative;
    margin-bottom: .5rem;
    cursor: pointer;

    .MuiBadge-root {
        max-width: 100%;
    }
}
