.Price {
    .priceBlock {
        margin-bottom: 1rem;
    }

    .multiPriceBlock {
        margin-top: 0;
    }

    .optionsTitle {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .multiPriceTableTitle {
        margin-top: 1rem;
        margin-bottom: -.5rem;
    }

    .multiPriceTableAdd {
        margin-top: 1rem;
    }

    .flexiblePricePlaceholder {
        margin-top: 0;
        margin-bottom: 1rem;
    }

    .flexiblePriceTableResult,
    .multiPriceTableResult {
        .MuiGrid-item {
            display: flex;
            align-items: center;
            padding-bottom: 1rem;
        }
    }

    .flexiblePriceBlock,
    .flexiblePriceTable,
    .multiPriceTable {
        margin-bottom: 1rem;
        margin-top: 0;
    }

    .flexiblePriceTable,
    .multiPriceTable {
        margin-top: 1rem;
        padding: 1rem;
        background: #f1f1f1;
        border-radius: 15px;

        .MuiGrid-container {
            margin-bottom: 1rem;
            border-bottom: 1px solid #ccc;

            &:last-child {
                border: 0;
                margin-bottom: 0;

                .MuiGrid-item {
                    padding-bottom: 0;
                }
            }
        }

        .removeOption {
            cursor: pointer;
        }

        .removeOptionCell {
            justify-content: end;
        }
    }

    .verticalAlignCell {
        display: flex;
        align-items: center;        
    }
}
