.UserSettingsBlock {
    .linkWrap {
        cursor: pointer;
        display: flex;
        align-items: center;
        position: relative;
        border: 1px solid #e1e1e1;
        box-sizing: border-box;
        border-radius: 15px;
        padding: 1rem;
        width: 100%;

        .MuiSvgIcon-root {
            margin-right: .5rem;
        }
    }
}
