.ProductAddonsField {
    .addonsTable {
        margin-top: 1rem;
        padding: 1rem;
        background: #f1f1f1;
        border-radius: 15px;

        .MuiGrid-item {
            display: flex;
            align-items: center;
        }

        .MuiGrid-container {
            margin-bottom: 1rem;
            border-bottom: 1px solid #ccc;

            .MuiGrid-item {
                padding-bottom: 1rem;
            }

            &:last-child {
                border: 0;
                margin-bottom: 0;

                .MuiGrid-item {
                    padding-bottom: 0;
                }
            }
        }

        .counter {
            font-size: .8rem;
            font-style: italic;
            opacity: .8;
        }

        .removeOption {
            cursor: pointer;
        }
    }

    .pseudoLinkWrap {
        display: flex;
        align-items: center;
        margin-top: 2rem;
    }
}
