.SearchWrap {
    margin-bottom: 1rem;
    position: fixed;
    width: calc(100% - 2rem);
    left: 1rem;
    top: 74px;
    z-index: 1302;

    @media (min-width: 600px) {
        top: 30px;
    }

    .SearchWrapContainer {
        position: relative;

        .MuiCircularProgress-root {
            width: 30px!important;
            height: 30px!important;
            color: #92bcd9;
        }
    }

    .searchResultPreloader {
        position: fixed;
        left: 50%;
        top: 50%;

        .MuiCircularProgress-root {
            color: #fff;
        }

        @media (min-width: 600px) {
            position: relative;
            left: auto;
            top: auto;
            min-height: calc(100vh - 160px);
        }
    }

    .SearchWrapInput {
        .MuiInputBase-input {
            padding-left: 3rem!important;
        }

        .MuiInputLabel-formControl {
            padding-left: 2rem!important;
        }
    }

    .SearchWrapIcon {
        position: absolute;
        top: 18px;
        left: 16px;
        opacity: .5;
    }

    @media (min-width: 600px) {
        max-width: calc(900px + 4rem);
        left: 50%;
        top: 86px;
        transform: translateX(-50%);

        .SearchWrapContainer {
            transform: translateX(-50%);
            width: calc(100% - 2rem);
        }
    }

    .MuiInputBase-root {
        // box-shadow: 0 0 40px #111;
        // box-shadow: 0 0 40px #fff;
        // border: 3px solid #4d7395;
        // border: 5px solid #335a7e;
        // border: 3px solid #79a6cf;
        outline: 5px solid #81c3ff61;
        border-color: #a5d1fb;
        // box-shadow: 0 0 10px 15px #919191;
    }

    .SearchWrapClose {
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 18px;
        color: #999;

        cursor: pointer;
    }

    .SearchWrapLabel {
        color: #fff;
        font-style: italic;
        font-size: 12px;
        padding-right: 1rem;
        text-shadow: 0 0 4px #000;
        white-space: nowrap;
        overflow: hidden;
        padding-left: 18px;
        text-align: left;
    }

    .searchResultItemWrapResults {
        display: inline-flex;
        align-items: center;
        padding: .2rem .2rem .3rem .7rem;
        margin-top: .2rem;
        background: rgb(0 0 0/ 70%);
        color: #fff;
        border-radius: 10px;
        font-size: 10px;
        margin-right: 20px;
        border: 1px solid #fff;
        cursor: pointer;

        + .searchResultSectionResult {
            margin-top: -10px;
        }

        .MuiBadge-badge {
            // margin-right: -8px;
            position: absolute;
            right: -30px;
            top: 0;
        }

        .MuiBadge-root {
            padding-right: .1rem;
            // margin-right: .5rem;
        }

        .MuiSvgIcon-root {
            // opacity: .5;
        }
    }

    .searchResultItemWrap {
        position: relative;

        max-height: calc(100vh - 130px);
        overflow: auto;

        // margin-top: 10px;
        margin-top: 2px;

        border-radius: 15px;

        @media (min-width: 600px) {
            transform: translateX(-50%);
            width: calc(100% - 4rem);

            max-height: calc(100vh - 160px);
        }
    }

    .searchResultSectionTitle {
        color: #fff;
        text-align: left;
        padding-left: .8rem;
        margin-top: 0;
        margin-bottom: -.8rem;
        font-size: .8rem;
        text-shadow: 0 0 10px #000;
    }

    .searchResultSectionResult + .searchResultSectionTitle {
        margin-top: .8rem;
    }

    .searchResultSectionResult {
        padding-bottom: 3rem;

        @media (min-width: 600px) {
            padding-bottom: 0;

            display: flex;
            flex-wrap: wrap;
            justify-content: start;
            // align-items: flex-start;
            // align-items: center;

            .searchResultItem {
                width: calc(33% - 2.5rem);
            }
        }
    }

    .searchResultItemContent {
        align-items: start;
        display: flex;
        flex-direction: row;
    }

    .searchResultItem {
        overflow: hidden;
        background: #fff;
        border-radius: 15px;

        padding: .8rem;

        align-items: center;

        text-align: left;

        margin: 0 .5rem;
        margin-top: 1rem;

        @media (max-width: 599px) {
            &:first-child {
                margin-top: 14px;
            }
        }

        .thumbnail {
            max-width: 120px;
            margin-right: 1rem;

            @media (max-width: 599px) {
                float: left;
                max-width: 78px;
            }

            @media (min-width: 600px) {
                max-width: 90px;
            }
        }

        .content {
            width: 100%;
        }

        @media (max-width: 599px) {
            .searchResultItemTags {
                clear: both;

                .MuiChip-root {
                    margin-top: 1rem!important;
                }
            }

            .searchResultItemLink {
                .emulateButton {
                    text-align: center;
                    display: block;
                    box-sizing: border-box;
                    width: 100%;
                }
            }
        }

        .category {
            opacity: .6;
            width: 100%;
            font-size: 14px;

            .MuiChip-label {
                font-size: 14px;
            }
        }

        .description {
            font-size: 14px;
            margin-bottom: .5rem;
        }

        .searchResultItemParent {
            font-size: .8rem;
            opacity: .8;
        }

        .searchResultItemTitle {
            font-size: 1.1rem;
            line-height: 1.4rem;
            font-weight: 500;

            // margin: 1rem 0;
            margin-bottom: .5rem;

            word-break: break-all;

            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            
            @media (min-width: 600px) {
                font-size: 1rem;
            }
        }

        .searchResultItemBanner {
            border-radius: 10px;
        }

        .searchResultItemDetails {
            margin-bottom: .5rem;
        }

        .searchResultItemHighlights {
            margin-bottom: 1rem;
        }

        .searchResultItemHighlight {
            font-size: .8rem;
            background: #efefef;
            border-radius: 10px;
            padding: .5rem 1rem;
        }

        .searchResultItemHighlight + .searchResultItemHighlight {
            margin-top: .5rem;
        }

        .searchResultItemTags {
            margin-top: -1rem;
            margin-bottom: 1rem;

            .MuiChip-root {
                margin-right: .5rem;
                margin-top: .5rem;
            }
        }

        .searchResultItemDetails {
            .labelWrap {
                padding: 0;
                margin: 0;

                @media (max-width: 599px) {
                    display: block;
                }
            }

            @media (min-width: 600px) {
                margin: .5rem 0;
                display: flex;

                .recommendation {
                    display: flex;
                    width: 100%;

                    .labelWrap {
                        width: 50%;
                    }
                }
            }
        }

        .searchResultItemLink {
            .emulateButton {
                display: block;
                width: 100%;

                @media (min-width: 600px) {
                    display: inline;
                    padding: 5px 10px;
                }
            }
        }
    }
}
