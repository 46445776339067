.Service {
    .details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        margin-bottom: 1rem;
        padding-bottom: .5rem;
        padding-top: .5rem;
        height: 56px;

        .price {
            align-items: start;

            .label {
                padding-right: 1rem;
            }
        }

        &.detailsOneButton {
            // display: block;

            @media (min-width: 600px) {
                justify-content: end;
                border: 0;
                margin-top: -80px;
                margin-right: 10px;
                margin-bottom: 2rem;
            }
        }

        @media (max-width: 599px) {
            padding-top: 1rem;
            padding-bottom: 1rem;
            // margin-bottom: 2rem;
        }
    }

    .multiPrices {
        padding: 1rem;
        margin-bottom: 1rem;
        border-bottom: 1px solid #ccc;
    }

    .price {
        font-size: 18px;
        margin: 0;

        .ShopProductPrice {
            margin: 0;
            padding: 0;;
        }
    }

    .categories {
        padding: 0 .5rem;
        margin-bottom: 1rem;

        @media (min-width: 600px) {
            padding: 0 1.5rem;
        }

        .MuiChip-root {
            margin-right: .5rem;

            @media (max-width: 599px) {
                margin-bottom: .5rem;
            }
        }
    }

    figure,
    img {
        width: 100%;
        margin: 0;
        border-radius: 15px;
    }

    @media (min-width: 600px) {
        .ShopingCartButton.multi {
            margin: 0 -1rem;
            margin-bottom: -1rem;

            .multiPriceItem {
                display: inline-flex;
                width: calc(50% - 4rem);
                padding: 0 2rem 1rem;
            }
        }
    }

    @media (max-width: 599px) {
        .ShopingCartButton {
            width: 100%;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
    }
}
