.ProductOptions {
    .notAvailableOption {
        opacity: .5;

        .label {
            display: none;
        }
    }

    .label {
        font-size: 14px;
    }

    .labelWrap {
        padding-left: 0!important;
        margin-top: .5rem;

        .label {
            font-size: 1rem;
        }

        .MuiChip-root {
            .label {
                display: inline;
            }
        }
    }
}

.ProductOptionsItem {
    padding-left: 1rem!important;
}

.Content {
    .ProductOptions {
        padding-left: 1rem;
        padding-right: 1rem;

        margin-bottom: 2rem;

        @media (min-width: 600px) {
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }
}
