.SelectCityDialog {
    font-size: 18px;

    @media (min-width: 600px) {
        // width: 440px;
    }

    .City {
        position: relative;
        // border: 1px solid #ddd;
        background-size: 100% auto!important;
        background-position: 0 50%!important;

        padding: 0.5rem 1.2rem;
        border-radius: 10px;
        margin-bottom: 1rem;

        @media (min-width: 600px) {
            // width: 440px;
            min-height: 100px;
        }

        .Wrap {
            min-height: 3rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &.inProgress {
            // opacity: .5;
            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: #fff;
                opacity: .8;
            }

            .city,
            .country {
                color: #596a78;
                text-shadow: none;
            }
        }

        .city {
            display: flex;
            align-items: center;
            font-size: 1.5rem;
            text-transform: uppercase;

            text-shadow: 0 0 10px #000;
            color: #fff;

            font-weight: 700;
            letter-spacing: 2px;
            z-index: 1;

            .MuiSvgIcon-root {
                margin-right: .5rem;
            }
        }

        .country {
            // display: block;
            // position: absolute;
            // right: 1rem;
            // top: .8rem;
            background: rgba(255, 255, 255, 0.8);
            border-radius: 5px;
            padding: 2px 10px;
            z-index: 1;
        }

        .mode {
            position: relative;
            display: inline-block;

            font-size: 14px;
            margin-top: -5px;
            margin-left: -5px;
            z-index: 1;

            color: #fff;
            background: rgb(28 169 220 / 90%);
            text-shadow: 0 0 10px #00549a;
            padding: .5rem 1rem;
            border-radius: 15px;
        }
    }

    .Details {
        display: flex;
        align-items: center;
        // justify-content: center;
        font-size: 12px;
        position: relative;
        top: 1rem;

        .detail {
            display: flex;
            align-items: center;
            margin: 0 .5rem;
    
            .logo {
                height: 10px;
                margin-right: .5rem;
            }
        }
    }

    .citiesWrap {
        position: relative;

        &:after {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background: #fff;
            box-shadow: 0 0 10px #fff;
        }
    }

    .cities {
        max-height: 198px;
        overflow: auto;
        margin-bottom: 1.5rem;
        margin-top: 0;

        @media (min-width: 600px) {
            max-height: 70vh;
        }
    }

    .Add {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        // margin-bottom: 2rem;
        // border: 0;
        color: #032d34;
        background: rgba(0,0,0,0.02);
        // margin-top: 2rem;
        // background: none;
        // padding: 0;
        // padding-left: .5rem;
        // margin-bottom: 2rem;

        .city {
            font-size: 1.2rem;
            color: #032d34;
            text-shadow: none;
        }
    }

    .Links {
        font-size: 16px;
        text-align: center;
        margin: 1rem -1rem;
        margin-top: 2rem;
        display: flex;

        .pseudoLink {
            border: 1px solid #ccc;
            display: flex;
            width: 100%;
            border-radius: 10px;
            margin: 0 1rem;
            padding: .5rem;
            align-items: center;
            justify-content: center;
            text-decoration: none;

            .MuiSvgIcon-root {
                margin-right: .5rem;
            }
        }
    }
}
