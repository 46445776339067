.ShopingCartButton {
    // white-space: nowrap;

    .MuiButtonBase-root {
        padding-top: 0;
        
        padding-bottom: 0;
        height: 56px;

        .MuiSvgIcon-root + .text {
            margin-left: .5rem;
        }
    }

    .pricePrefix {
        white-space: nowrap;
        display: none;
    }

    .oldPrice {
        margin: 0;
        margin-top: -.5rem;
    }

    .labelPrices {
        margin-bottom: 1rem;
    }

    .MuiButtonBase-root.selected {
        background: #fff;
        box-shadow: none;
        color: #000;
        outline: 1px solid #333;
    }

    // .MuiSvgIcon-root {
    //     margin-right: .5rem;
    // }

    // .sep {
    //     padding: 0 1rem;
    //     display: none;
    // }

    &.form {
        .MuiButtonBase-root {
            width: 100%;
        }
    }

    &.multi {
        // display: flex;

        // .MuiButton-root {
        //     margin-left: .5rem;
        // }

        .multiPriceItem {
            display: flex;
            // width: 100%;
            justify-content: space-between;
            margin-bottom: .5rem;
            // align-items: center;
            // white-space: break-spaces;

            // .MuiGrid-item {
            //     display: flex;
            //     align-items: center;
            // }

            .priceWrap {
                word-break: break-all;
                padding-right: 1rem;
            }

            .priceValue {
                font-size: 1.2rem;
            }

            .pricePrefix {
                // opacity: .9;
            }

            .MuiButtonBase-root {
                min-width: 70px;
            }

            .price {
                display: block;
            }
        }

        .multiPriceItemMobile {
            // margin-left: -.5rem;
            // margin-right: -5rem;
            // width: calc(100% + 1rem);

            // .MuiButton-root {
            //     margin-left: 0;
            //     justify-content: space-between;

            //     white-space: break-spaces;
            //     text-align: left;
            //     line-height: 1rem;

            //     .title,
            //     .price {
            //         display: flex;
            //         align-items: center;
            //     }

            //     .title {
            //         padding-right: .5rem;
            //     }
            // }
        }
    }
}
