.NotificationWidget {
    display: flex;
    flex-direction: column;
    // margin-bottom: 1rem;

    .pre {
        font-size: 12px;
        opacity: .8;
    }

    pre {
        margin-bottom: 0;
    }

    .orderId {
        font-size: 12px;
        color: #777;
        margin-bottom: .5rem;
    }
}

.NotificationTitle {
    @media (max-width: 599px) {
        flex-direction: column;
    }

    .text {
        padding-top: .2rem;

        @media (max-width: 599px) {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}

.NotificationSubitle {
    font-size: .8rem;
    font-style: italic;
    opacity: .6;
}

.OrderActions {
    // margin-bottom: 1rem;
}

.NotificationActions {
    // overflow: hidden;
    // margin-top: 1rem;
    // margin-bottom: 0;
    // padding-top: 1rem;

    // .MuiChip-root {
    //     padding: 1rem;
    //     font-size: 14px;
    // }

    // .MuiButton-root {
    //     margin-bottom: .5rem;
    // }
}
