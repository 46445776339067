.OrderDetails {
    margin-bottom: .5rem;

    .OrderWrap {
        margin-top: .5rem;
        background: #fff;
        border-radius: 10px;
        padding: 1rem;

        // .Order {
        // }

        .Title {
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
        }

        .ContentImage {
            width: 2rem;
            height: 2rem;
            border-radius: 5px;
            margin-right: 1rem;
        }

        .Partner {
            margin-top: 2rem;
            border-top: 1px solid #eee;
            padding-top: 1rem;
            display: flex;
            align-items: center;

            .partnerAvatar {
                width: 20px;
                border-radius: 5px;
            }

            .pseudoLink {
                margin-left: .5rem;
            }
        }
    }

    .Addons {
        .addonItem {
            margin-right: 1rem;

            .q {
                opacity: .8;
                font-size: 14px;
                position: relative;
                top: -2px;
            }
        }
    }

    .Options,
    .Addons {
        .title {
            display: block;
            margin-left: .5rem;
            margin-bottom: .5rem;
            font-size: 14px;
        }
    }
}
