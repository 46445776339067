.OrderTitle {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;

    @media (max-width: 599px) {
        flex-direction: column-reverse;

        .Label {
            margin-bottom: 1rem;
        }
    }
}
