.InlineActions {
    position: relative;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    z-index: 1;

    &.InlineActionsOpen {
        z-index: 2;
    }

    .actions {
        position: absolute;
        right: 50px;
        top: 0;

        display: flex;

        .ActionButton {
            margin-right: 0;
            margin-left: .3rem;
            margin-right: .3rem;
        }

        .MuiSvgIcon-root {
            box-shadow: 0 0 50px #fff;
        }
    }
}
.LinksLines,
.NodeLinesTemplate {
    .InlineActionsUp .actions {
        width: 100%;

        .ActionButton {
            width: 21%;
            padding-bottom: 21%;

            @media (min-width: 600px) {
                width: 18%;
                padding-bottom: 18%;
            }
        }
    }
}

.ActionsCell {
    .InlineActions {
        top: 1px;
    }
}

.InlineActionsUnfolded {
    white-space: nowrap;
    width: 100%;
    padding: 0;

    .ActionButton {
        margin: 0 .5rem;
    }
}

.InlineActionsUp {
    transition: margin-top .1s ease-in-out;
    width: 100%;
    justify-content: end;

    .actions {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: end;

        right: 0;
        top: 50px;

        width: 100%;

        @media (min-width: 600px) {
            right: 0;
        }
    }
}

.CurrentPageInlineActions {
    position: absolute;
    right: 7px;
    top: 12px; //15px;
    z-index: 1;
    width: auto;

    .actions {
        background: #fff;
        box-shadow: 0 0 20px #fff;
    }
}

.ShopProductsList,
// .childrensList,
.LinksTile {
    .InlineActions {
        position: absolute;

        right: -5px;
        top: -5px;
    }

    .ActionButton {
        &.disabled {
            display: none;
        }
    }

    .actions {
        right: 5px;

        .ActionButton {
            position: relative;
            width: 50%;
            padding-bottom: 50%;
            height: 0;
            margin-left: 0;
            margin-right: 0;

            .MuiSvgIcon-root {
                box-sizing: border-box;
                position: absolute;
                left: 10%;
                top: 10%;
                width: 80%;
                height: 80%;
                padding: .8rem;

                @media (min-width: 600px) {
                    padding: 1.2rem;
                }

                // @media (min-width: 600px) {
                //     padding: .8rem;
                // }
            }
        }
    }
}

.NodeLinesCompactTemplate {
    .InlineActions {
        // top: 10px;
        width: 100%;
        justify-content: end;
    }
}

.LibraryTemplate {
    .LinksTile {
        .InlineActions {
            top: -10px;
        }
    }

    .LinksTile {
        .actions {
            .ActionButton {
                .MuiSvgIcon-root {

                    @media (min-width: 600px) {
                        left: 15%;
                        top: 15%;
                        width: 70%;
                        height: 70%;
                        // padding: 1rem;
                        padding: .7rem;
                    }

                    // padding: .8rem;

                    // @media (min-width: 600px) {
                    //     left: 15%;
                    //     top: 15%;
                    //     width: 70%;
                    //     height: 70%;
                    //     padding: .7rem;
                    // }
                }
            }
        }
    }
}

.childrensList {
    .actions {
        background: #fff;

        .MuiSvgIcon-root {
            box-shadow: 0 0 50px #fff;
        }
        
    }
}

.TableWrap {
    .InlineActions {
        @media (max-width: 599px) {
            margin-top: 1rem;
        }
    }
}

.InlineActionsUp {
    &.InlineActionsOpen {
        margin-top: -34px;
    }
}

.ShopProductsList,
.LinksTile {
    // .LinksTileItem.poster 
    .InlineActionsUp.InlineActionsOpen {
        margin-top: -46px;
    }
}

.TileSections {
    .InlineActionsUp.InlineActionsOpen {
        margin-top: -50px;

        @media (min-width: 600px) {
            margin-top: -56px;
        }
    }

    .actions {
        .ActionButton {
            .MuiSvgIcon-root {
                padding: .4rem;

                @media (min-width: 600px) {
                    padding: .8rem;
                }
            }
        }
    }
}

.LibraryTemplate {
    .InlineActionsUp.InlineActionsOpen {
        margin-top: 0;
    }
}
