.ConversationPage {
    position: relative;

    .recipient {
        .message {
            padding-left: 0;
        }
    }

    &.Multi .recipient {
        .message {
            padding-left: 1.5rem;
        }
    }

    .pre_not_readed {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;

        .wrap {
            width: 100%;
            background: none;
            border-top: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
            text-align: center;
            font-size: 12px;
            color: #555;
        }
    }

    .InfinityPreloader {
        background: none;
        border: 0;
    }

    .pageTopTitle {
        // padding-bottom: 0;

        .pageTitle {
            display: flex;
            align-items: center;

            // .AvatarWrap {
            //     height: 28px;
            //     margin-right: .5rem;
            // }

            .ConversationTopicrDialog,
            .AvatarWrap,
            .TopicAvatar {
                width: 30px;
                height: 30px;
                min-width: 30px;
                margin-right: .5rem;

                .MuiIcon-root {
                    font-size: 1rem;
                }
            }
        }
    }

    .FiltersLine {
        margin-bottom: 1rem;
    }

    // .TableWrap table.Table td:first-child {
    //     padding-left: .5rem;
    // }

    .FullHeightContentBlockContent {
        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 1px;
            background: #fff;
            z-index: 999;
            box-shadow: 0 2px 6px #fff;
        }
    }

    .TableWrap {
        .category {
            td {
                background: none;
                text-align: center;
            }

            .MuiChip-label {
                // background: #eee;
                /* color: #fff; */
                border-radius: 10px;
                padding: 3px 20px;
                border: 1px solid #ddd;
            }
        }
    }

    .TableWrap table.Table .item {
        display: block;
        margin-bottom: 1rem;
        max-width: 100%;
    }

    .td_message {
        display: block;
        box-sizing: border-box;
        width: 100%;
        // padding-left: 1rem;
        // padding-right: 1rem;
    }

    .TableWrap table.Table .item {
        // margin-bottom: 2rem;

        &.tr_sender .timestamp {
            right: 0;
        }

        &.tr_recipient .timestamp {
            left: -86px;

            // @media (min-width: 600px) {
            //     margin-top: 1.3rem;
            // }
        }
    }

    .notReadedMessages {
        position: absolute;
        bottom: 148px;
        right: 0;

        background: #fff;
        z-index: 999;
        padding: .5rem;
        border-radius: 50%;
        border: 1px solid #ddd;

        cursor: pointer;
    }

    .pageTopTitle {
        .pageTitle {
            width: 100%;
            position: relative;

            white-space: nowrap;

            // padding-left: 32px;

            // .AvatarWrap {
            //     position: absolute;
            //     left: -4px;
            //     top: 3px;
            // }

            .titleText {
                text-overflow: ellipsis;
                display: block;
                overflow: hidden;
                margin-right: 1rem;
            }

            .MuiBadge-root {
                white-space: nowrap;
                // margin-left: 1.2rem;

                .MuiBadge-badge {
                    right: 20px;
                }
            }

            // // @media (max-width: 599px) {
            // // .MuiBadge-root {
            //     margin-left: 0;
            //     position: absolute;
            //     right: 1rem;
            //     top: 1.2rem;
            // }
            // // }

            .Avatar {
                width: 28px;
                height: 28px;
                min-width: 28px;
                min-height: 28px;
            }
        }
    }

    .TableWrap table.Table .tr_sender {
        margin-left: 3rem;
        width: calc(100vw - 6rem);

        @media (min-width: 600px) {
            width: auto;
            margin-left: 6rem;
            margin-right: 1rem;
        }
    }

    .TableWrap table.Table .tr_recipient {
        margin-right: 3rem;
        width: calc(100vw - 6rem);

        @media (min-width: 600px) {
            width: auto;
            margin-right: 7rem;
        }

        td {
            background-color: #eaf9ff;
        }
    }

    @media (min-width: 600px) {
        // .TableWrap table.Table .item {
            // max-width: 50%;
        // }

        .TableWrap table.Table td:first-child {
            padding-left: 1rem;
        }
    
        // .TableWrap table.Table .tr_sender {
        //     margin-left: 2rem;
        // }

        // .TableWrap table.Table .tr_recipient {
        //     margin-left: 1rem;
        // }
    }
}

.noMessanger {
    .notReadedMessages {
        bottom: 16px;
    }
}

.ConversationMessenger {
    // position: fixed;
    // padding: 1rem;
    // bottom: 0;
    // height: 130px;
    // left: 0;
    // right: 0;
    display: flex;
    border-top: 5px solid #fff;
    padding-top: 5px;
    box-shadow: 0 -10px 10px #fff;
    background: #fff;
    overflow: hidden;

    .buttons {
        // width: 48px;
        text-align: right;
        // width: 92px;
        // margin-right: 2rem;

        .sendButton {
            margin-bottom: 18px;
            border-color: #a2a2a2;

            padding: 12px;

            border-radius: 12px!important;
            // border-color: #555;

            .MuiSvgIcon-root {
                width: 16px;
                height: 16px;
            }
        }

        .UploadImage {
            .fileUploadButton .action {
                padding: 10px!important;
                margin-left: 0;
                background: #f0f0f0;
                color: #333;
                box-shadow: none;
                width: auto;
                height: auto;
                min-width: auto;
                min-height: auto;
            }
        }
    }

    .textName {
        // margin-left: 3rem;
        width: 100%;
        margin-right: .5rem;

        .MuiButton-root {
            margin-bottom: 2rem;
        }
    }
}

.ConversationPage {
    @media (max-width: 599px) {
        .FullHeightContentBlockWrap {
            .FullHeightContentBlockContent {
                margin-right: -16px;
                // margin-left: -16px;
                // margin-right: -16px;    
            }    
        }
    }
}
