.ShortNotifications {
    @media (min-width: 600px) {
        width: 500px;
    }

    .MuiSnackbarContent-root {
        width: 100%;
        min-width: auto;
        padding: 1rem;

        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .MuiSnackbarContent-message {
            padding: 0;
            width: calc(100% - 40px);
        }

        .MuiSnackbarContent-action {
            margin-right: 0;
        }
    }
}
