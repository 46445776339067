.HistoryShopOrderAdminPage {
    .orderStatus {
        margin-bottom: 1rem;
        margin-left: 1rem;

        .labelWrap {
            padding-left: .5rem;
        }

        @media (max-width: 599px) {
            margin-left: 0;

            .labelWrap {
                padding-left: 1.5rem;
            }

            .MuiChip-root {
                width: 100%;
            }
        }
    }

    .orderDetailsAction {
        margin-bottom: 1rem;
        padding-left: 2rem;
    }

    @media (max-width: 599px) {
        .OrderActions {
            .MuiButton-root {
                margin-bottom: 1rem;
            }
        }
    }
}
