.ShopProductPrice {
    display: inline-flex;

    .oldPrice {
        display: block;
        text-decoration: line-through;
        color: #888;
        margin-bottom: -.5rem;
        margin-top: -.5rem;
    }

    .pricePrefix {
        white-space: nowrap;
        opacity: .8;
    }

    .priceValue {
        display: inline-block;
    }
}
