.ShopProductsList {
    margin-bottom: 3rem;
    padding: 0 .5rem;

    .productWrap {
        position: relative;

        &.productNotAvailable {
            opacity: .9;
        }
    }

    .productTitle {
        display: flex;
        align-items: center;
        height: 65px;
        // line-height: 1.3;
        padding-left: .5rem;
        padding-top: .5rem;

        height: 48px;
        margin-bottom: .5rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .photoWrap {
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 15px;
    }

    .button {
        margin-bottom: 1rem;
    }

    .ShopProductPrice {
        font-size: 1.3rem;
        height: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0;
        white-space: nowrap;

        .oldPrice {
            font-size: 1rem;
        }

        @media (max-width: 599px) {
            font-size: 18px;
        }
    }

    .ShopingCartButton {
        text-align: left;

        .MuiSvgIcon-root {
            width: 16px;
        }

        .ShopProductPrice {
            font-size: .8rem;

            height: auto;

            .oldPrice {
                color: #bbb;

                font-size: .7rem;
            }
        }
    }
}
