.Product {
    .photo {
        width: 100%;
        border-radius: 15px;
    }

    .galleryWrap {
        margin-top: 1rem;
        margin-left: -.5rem;
        margin-right: -.5rem;

        @media (min-width: 600px) {
            margin-bottom: -2rem;
        }

        .CarouselPhotos {
            min-height: 170px;            
        }
    }
    
    .notAvailable {
        padding-left: 1.5rem;
        padding-bottom: 2rem;
        color: #ae0000;
    }

    .productOrder {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        // padding-bottom: 2rem;

        .ShopingCartButton.free,
        .ShopingCartButton.noPrice,
        .ShopingCartButton.multi {
            width: 100%;
        }
    }

    .ShopingCartButton {
        padding-bottom: 2rem;
    }

    .ShopProductPrices {
        // padding-bottom: 2rem;
        padding-left: 2rem;
    }

    .ShopingCartButton.multi {
        padding-left: 2rem;
        padding-right: 2rem;

        @media (max-width: 599px) {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }

    .ShopProductPrice {
        font-size: 2rem;
        margin-top: -1rem;
        display: flex;
        align-items: center;
        // padding-bottom: 2rem;

        @media (max-width: 599px) {
            padding-bottom: 1.5rem;
        }

        .oldPrice {
            font-size: 1rem;
            // margin-right: .5rem;
            margin-left: .5rem;
        }

        .pricePrefix {
            font-size: 1rem;
            margin-left: .5rem;
        }
    }
}
