.ProductOptionsField {
    .optionsTable {
        margin-top: 1rem;
        padding: 1rem;
        background: #f1f1f1;
        border-radius: 15px;
    }

    .removeOption {
        cursor: pointer;
    }

    .optionsTableTitle {
        display: flex;
        align-items: center;
    }

    .optionsTableAction {
        text-align: right;
    }

    .optionListEditItem {
        margin-bottom: 1rem;
        border-bottom: 1px solid #ccc;

        .MuiGrid-item {
            padding-bottom: 1rem;
            display: flex;
            align-items: center;
        }
    }

    .pseudoLinkWrap {
        display: flex;
        align-items: center;
        margin-top: 2rem;
    }
}
