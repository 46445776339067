.FullHeightContentBlockWrap {
    margin-left: -16px;
    margin-right: -16px;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 1px;
        box-shadow: 0 4px 10px #fff;
        z-index: 999;
    }
}

.FullHeightContentBlock {
    overflow: hidden;
    position: relative;
    padding-left: 16px;
    padding-right: 16px;
    // min-height: calc(100vh - 300px);

    @media (min-width: 600px) {
        min-height: calc(100vh - 350px);
    }

    .BlockPreloader {
        background: #fff;
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .TableAutoWrap {
        overflow: visible;
        // padding-bottom: 1rem;
    }

    .FullHeightContentBlockContent {
        height: 100%;
        overflow: auto;
        padding-right: 1rem;

        &.hidden {
            opacity: 0;
        }
    }
}
