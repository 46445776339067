.ShopCategoryLabel {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 300;
    padding-bottom: 1rem;
    padding-top: 2rem;

    &:first-child {
        padding-top: 0;
    }
    
    .img {
        width: 32px;
        height: 32px;
        margin-right: .5rem;
    }
}
